import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo.js";
import buttonStyles from "../components/buttons.module.scss";

const HowToUse = () => {
  return (
    <Layout>
      <SEO title="Notion Covers | How to Use" />
      <h2>How to Use</h2>

      <h3>How to add a Notion cover</h3>
      <p>To add a cover:</p>
      <ul>
        <li>Open a Notion page.</li>
        <li>
          Hover your cursor above the page title and choose{" "}
          <code>Add cover</code>.
        </li>
        <li>
          Hover your cursor over the newly added cover and choose{" "}
          <code>Change cover</code>.
        </li>
        <li>
          A menu will appear. Click <code>Upload</code> and then{" "}
          <code>Choose an image</code>.
        </li>
        <li>
          A file browser will appear. Select your desired cover image and click{" "}
          <code>Open</code>.
        </li>
      </ul>

      <h3>How to change a Notion cover</h3>
      <p>To change a cover:</p>
      <ul>
        <li>
          Hover your cursor over your existing cover and choose{" "}
          <code>Change cover</code>.
        </li>
        <li>
          A menu will appear. Click <code>Gallery</code> to choose from the
          Notion gallery. Click <code>Upload</code> to choose a file from your
          computer. Click <code>Link</code> and enter a URL of an image. Or,
          click <code>Unsplash</code> to choose a beautiful image from a library
          of free-to-use stock photos.
        </li>
      </ul>

      <h3>How to reposition a Notion cover</h3>
      <p>To adjust the position of the image within the cover:</p>
      <ul>
        <li>
          Hover your cursor over your existing cover and choose{" "}
          <code>Reposition</code>.
        </li>
        <li>Click and drag the image to adjust its position.</li>
        <li>
          Click <code>Save position</code> to confirm. Or, click{" "}
          <code>Cancel</code> to exit without saving.
        </li>
      </ul>

      <h3>How to remove a Notion cover</h3>
      <p>To remove a cover:</p>
      <ul>
        <li>
          Hover your cursor over your existing cover and choose{" "}
          <code>Change cover</code>.
        </li>
        <li>
          Click <code>Remove</code>.
        </li>
      </ul>

      <hr />
      
      <div style={{textAlign: "center"}}>
        <Link className={buttonStyles.button} to="/">
          Browse Notion covers
        </Link>
      </div>
    </Layout>
  );
};

export default HowToUse;
